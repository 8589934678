import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const Roles = Loadable(lazy(() => import("src/pages/roles/index")));

const roles = [
    {
        path: "roles",
        children: [
            {
                index: true,
                element: <Roles />,
            },
        ],
    },
];

export default roles;
