import Ternary from "src/components/ternary";
import GetStartedTitle, {
  LoginTitle,
} from "src/layouts/auth/components/dynamic-header/components/titles";

const DynamicHeader = ({ type }) => {
  const isRegister = type === "register";

  return (
    <Ternary
      when={isRegister}
      then={<GetStartedTitle />}
      otherwise={<LoginTitle />}
    />
  );
};

export default DynamicHeader;
