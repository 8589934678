import useAuth from "src/hooks/useAuth";

const useIsAffiliate = () => {
  const { user } = useAuth();
  const { user_profile } = user;

  return Boolean(user_profile?.is_affiliate);
};

export default useIsAffiliate;
