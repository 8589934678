import { capitalCase } from "change-case";
import ActionButton from "./components/action-button";
import DynamicHeader from "./components/dynamic-header";
import Wrapper from "./components/wrapper";

const Auth = ({ title, type, children }) => {
  return (
    <Wrapper
      title={capitalCase(title)}
      actionButton={<ActionButton type={type} />}
      header={<DynamicHeader type={type} />}
    >
      {children}
    </Wrapper>
  );
};

export default Auth;
