import { Box, Typography } from "@mui/material";
import Page from "src/components/Page";
import BrandName from "src/components/brand-name";
import RootStyle from "src/pages/auth/shared/rootStyle";
import Header from "./components/header";

const Wrapper = ({ children, title, actionButton, header }) => {
	return (
		<Page title={title}>
			<RootStyle>
				<Header children={header} />
				<Box
					sx={{
						height: "100vh",
						maxWidth: "100%",
						position: "relative",
						width: {
							xl: "calc(100% - 700px)",
							md: "calc(100% - 550px)",
							sm: "calc(100% - 550px)",
						},
						display: { md: "flex", sm: "none", xs: "none" },
						backgroundImage: `url(${require("../../assets/loginimage.jpg")})`,
						backgroundSize: "cover",
					}}>
					<Typography
						variant='h4'
						variantMapping={{ h4: "h1" }}
						gutterBottom
						sx={{
							position: "absolute",
							zIndex: 2,
							color: "#fff",
							right: "20px",
							top: "20px",
						}}>
						{title}
					</Typography>
					<Box
						sx={{
							position: "absolute",
							zIndex: 2,
							color: "#fff",
							left: "20px",
							bottom: "150px",
							padding: "2rem",
						}}>
						<Typography variant='h4'>
							WELCOME TO <BrandName />
						</Typography>

						<Typography
							sx={{
								color: "#fff",
								mt: 2,
								mb: 2,
							}}>
							Bitcoin Trend & Forecast was incorporated in 2016
							and remains the world leader in Bitcoin and
							Cryptocurrency analysis serving thousands of members
							worldwide.
						</Typography>
						{actionButton}
					</Box>
				</Box>

				{children}
			</RootStyle>
		</Page>
	);
};

export default Wrapper;
