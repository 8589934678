import { Link, Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
// components
import { Button } from "@mui/material";
import { last } from "lodash";
import { WEBSITE_URL } from "src/config";
import Logo from "../components/Logo";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const pathname = window.location.pathname;
  const isError = last(pathname.split("/")) === "500";

  const button = isError ? (
    <Button href={WEBSITE_URL} size="small" component="a">
      Go to Home
    </Button>
  ) : (
    <Button to="/" size="small" component={Link} replace>
      Go to Home
    </Button>
  );

  return (
    <>
      <HeaderStyle>
        <Logo />
        {button}
      </HeaderStyle>
      <Outlet />
    </>
  );
}
