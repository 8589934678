import { Badge } from "@mui/material";
import { Link } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import Iconify from "./Iconify";
import { IconButtonAnimate } from "./animate";
import Ternary from "./ternary";

const CartWidget = () => {
  const { user, isAdmin, isSubAdmin } = useAuth();

  const isUser = !(isSubAdmin || isAdmin);

  return (
    <Ternary
      when={isUser}
      then={
        <IconButtonAnimate
          id="view-cart-btn"
          LinkComponent={Link}
          to={"/user/checkout"}
        >
          <Badge showZero badgeContent={user.cartCount} color="error" max={99}>
            <Iconify icon="eva:shopping-cart-fill" />
          </Badge>
        </IconButtonAnimate>
      }
    />
  );
};

export default CartWidget;
