import { Alert, Collapse, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useUserGuidanceData } from "src/store/user-guidance";
import Iconify from "./Iconify";
import Ternary from "./ternary";

const UserGuidanceBanner = () => {
  const [open, setOpen] = React.useState(true);
  const { palette } = useTheme();

  const data = useUserGuidanceData();

  return (
    <Ternary
      when={Boolean(data)}
      then={
        <Collapse in={open}>
          <Alert
            color="info"
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
                sx={{ color: palette.primary.main }}
              >
                <Iconify
                  icon="ion:close-outline"
                  sx={{ color: palette.primary.main }}
                />
              </IconButton>
            }
            sx={{
              mb: 2,
              backgroundColor: palette.primary.lighter,
              borderLeft: "solid 5px #7558A7",
              color: "#000",
              "& .MuiAlert-icon": {
                color: palette.primary.main,
              },
            }}
          >
            {data?.block}
          </Alert>
        </Collapse>
      }
    />
  );
};

export default UserGuidanceBanner;
