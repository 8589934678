import { LoadingButton } from "@mui/lab";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useNavigate } from "react-router";
import { FormProvider } from "src/components/hook-form";
import TwoFactorVerificationDialog from "src/components/twoFactorVerificationDialog";
import useQueryParams from "src/hooks/useQueryParams";
import axiosInstance from "src/utils/axios";
import { setSession } from "src/utils/jwt";

const TwoFactor = ({ methods }) => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    setError,
    watch,
    register,
    formState: { isSubmitting, errors },
  } = methods;
  const { queryObject } = useQueryParams();
  const open = queryObject.otp === "open";

  const TfaLogin = async (inputData) => {
    const reqData = new FormData();
    Object.entries(inputData).forEach(([k, v]) => reqData.append(k, v));

    try {
      const { data, status } = await axiosInstance.post(
        "api/twofaverify",
        reqData
      );
      if (status === 200) {
        localStorage.setItem("isAdmin", Boolean(data.user.is_super_admin));
        localStorage.setItem("menu", JSON.stringify(data.menu_lists));
        setSession(data.access_token);
        navigate(0);
      }
    } catch (err) {
      setError("code", { message: err.message });
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <FormProvider methods={methods} onSubmit={handleSubmit(TfaLogin)}>
        <DialogTitle
          sx={{
            mb: 2,
          }}
        >
          Two-Factor Authentication (2FA)
        </DialogTitle>
        <DialogContent
          sx={{
            paddingBottom: 0,
          }}
        >
          <TextField
            helperText={errors?.code?.message}
            error={Boolean(errors?.code)}
            autoFocus
            {...register("code")}
            size="small"
            fullWidth
            label="Enter OTP"
            type="number"
          />

          <LoadingButton
            sx={{
              mt: 1,
              mb: 2,
            }}
            type="submit"
            loading={isSubmitting}
            size="small"
            fullWidth
            variant="contained"
          >
            verify
          </LoadingButton>
        </DialogContent>

        {/* <TwoFactorVerificationDialog open={open} /> */}
      </FormProvider>
    </Dialog>
  );
};

export default TwoFactor;
