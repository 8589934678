import { lazy } from "react";
import Loadable from "../Loadable";

const SubscriptionTabs = Loadable(
  lazy(() => import("src/pages/userSide/subscriptions/subscriptionTabs"))
);

const Subscriptions = Loadable(
  lazy(() => import("src/pages/userSide/subscriptions/index"))
);

const Home = Loadable(
  lazy(() => import("src/pages/userSide/subscriptions/home/index"))
);

const Document = Loadable(
  lazy(() => import("src/pages/userSide/subscriptions/documents/index"))
);

const Events = Loadable(
  lazy(() => import("src/pages/userSide/subscriptions/events/index"))
);

const Videos = Loadable(
  lazy(() => import("src/pages/userSide/subscriptions/videos/index"))
);

const Comment = Loadable(
  lazy(() => import("src/pages/userSide/subscriptions/review/index"))
);

const BlogPosts = Loadable(
  lazy(() => import("src/pages/userSide/blogs/BlogPosts"))
);

const WhisperDrop = Loadable(
  lazy(() => import("src/pages/userSide/subscriptions/whisper/index"))
);

const subscriptions = {
  path: "subscriptions",
  children: [
    {
      index: true,
      element: <Subscriptions />,
    },
    { path: "whisper-drop", element: <WhisperDrop /> },
    { path: "blogs/:id", element: <BlogPosts /> },
    {
      path: ":name/:id",
      element: <SubscriptionTabs />,
      children: [
        { index: true, element: <Home /> },
        { path: "home", element: <Home /> },
        { path: "documents", element: <Document /> },
        { path: "events", element: <Events /> },
        { path: "videos", element: <Videos /> },
        { path: "review", element: <Comment /> },
      ],
    },
  ],
};

export default subscriptions;
