import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

RHFTextField.propTypes = {
    name: PropTypes.string,
};

export default function RHFTextField({
    name,
    sx,
    rules = {},
    props,
    ...other
}) {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            {...props}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...other}
                />
            )}
        />
    );
}
