import { snakeCase } from "change-case";

const getBrowserVersion = () => {
  var ua = navigator.userAgent;
  var tem;
  var M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M;
};

export const getBrowserVersionNumber = () => {
  const list = getBrowserVersion();
  if (list.length === 2) {
    return parseInt(list[1]);
  }
};
export const getBrowserName = () => {
  const list = getBrowserVersion();
  if (list.length === 2) {
    return list[0];
  }
};

const minimumBrowserVersion = {
  safari: 14,
  chrome: 90,
  firefox: 78,
  // firefox: 200,
  edge: 91,
  samsung: 13,
  ios_safari: 14,
};

export const isCurrentBrowserUnSupported = () => {
  const isIphone = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [name, version] = getBrowserVersion();
  const minSupportedVersion = isIphone
    ? minimumBrowserVersion.ios_safari
    : minimumBrowserVersion[snakeCase(name)];
  return parseInt(version) < minSupportedVersion;
};

export default getBrowserVersion;
