import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const AssignedRoles = Loadable(
    lazy(() => import("src/pages/assigned-roles/index"))
);

const property = [
    {
        path: "assigned-roles",
        element: <AssignedRoles />,
    },
];

export default property;
