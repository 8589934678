import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { setGuidance, useUserGuidanceDispatch } from "src/store/user-guidance";
import axiosInstance from "src/utils/axios";
import useAuth from "./useAuth";

const useFetchUserGuidancePath = () => {
  const { name, id, label, slug, category } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const routePath = location.pathname;
  const dispatch = useUserGuidanceDispatch();

  const checkPath = (routePath) => {
    switch (routePath) {
      case `/user/online-store/product-subscription/${name}`:
        return "/user/online-store/product-subscription/${name}";
      case `/user/online-store/my-orders/${id}`:
        return "/user/online-store/my-orders/${id}";
      case `/user/blogs/${id}`:
        return "/user/blogs/${id}";
      case `/user/subscriptions/view/${id}/home`:
        return "/user/subscriptions/view/${id}/home";
      case `/user/subscriptions/view/${id}/documents`:
        return "/user/subscriptions/view/${id}/documents";
      case `/user/subscriptions/view/${id}/events`:
        return "/user/subscriptions/view/${id}/events";
      case `/user/subscriptions/view/${id}/videos`:
        return "/user/subscriptions/view/${id}/videos";
      case `/user/subscriptions/view/${id}/comment`:
        return "/user/subscriptions/view/${id}/comment";
      case `/user/help-center/faqs/${label}`:
        return "/user/help-center/faqs/${label}";
      case `/user/help-center/knowledge-base/${slug}`:
        return "/user/help-center/knowledge-base/${view}";
      case `/user/help-center/mails/${label}`:
        return "/user/help-center/mails/${label}";
      case `/user/help-center/create-ticket/${category}`:
        return "/user/help-center/create-ticket/${category}";
      case `/subscriptions/blogs?type=subscriptions&id=${id}`:
        return "/subscriptions/blogs?type=subscriptions&id=${id}";
      default:
        return routePath;
    }
  };
  const path = checkPath(routePath);
  const fetchPath = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/api/user/guidance`, {
        params: {
          route_path: path ? path : routePath,
        },
      });
      if (status) {
        dispatch(setGuidance(data.data));
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
      console.log(err);
    }
  };

  const { isAdmin } = useAuth();

  useEffect(() => {
    if (isAdmin) return;

    if (routePath) fetchPath();

    return () => {
      dispatch(setGuidance(null));
    };
  }, [routePath, isAdmin]);
};
export default useFetchUserGuidancePath;
