import { useRoutes } from "react-router-dom";
import ViewPDF from "src/components/pdf-viewer";
import { MAINTENANCE_MODE } from "src/config";
import useAuth from "src/hooks/useAuth";
import GoogleLogin from "src/pages/auth/googleAuth";
import genAdmin from "./admin";
import auth from "./auth";
import main from "./main";
import MAINTENANCE_ROUTE from "./maintenance";
import user from "./user";

const Router = () => {
    const { isSubAdmin } = useAuth();

    return useRoutes(getRoutes(isSubAdmin));
};

const getRoutes = (isSubAdmin) => {
    const routes = [
        ...auth,
        ...user,
        ...genAdmin(isSubAdmin),
        {
            path: "OAuth",
            children: [{ path: "g/:g_token", element: <GoogleLogin /> }],
        },
        // { path: "/document/:name", element: <PDFViewer /> },
        {
            path: "/document/:name",
            element: <ViewPDF />,
        },
        ...main,
    ];

    // if (isCurrentBrowserUnSupported()) {
    //   return OUTDATED_BROWSER_ROUTE;
    // }

    return MAINTENANCE_MODE ? MAINTENANCE_ROUTE : routes;
};

export default Router;
