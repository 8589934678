import axios from "axios";
import React, { useEffect, useState } from "react";
import { HOST_API } from "src/config";

const useGetTermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const axiosInstance = axios.create({
    baseURL: HOST_API,
  });

  const fetchData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        "/api/terms-and-conditions"
      );
      if (status === 200) {
        setTermsAndConditions(data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return termsAndConditions;
};

export default useGetTermsAndConditions;
