import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useAuth from "src/hooks/useAuth";

import useIsAffiliate from "src/hooks/useIsAffiliate";
import useIsNetwork from "src/hooks/useIsNetwork";

import { useNavigate } from "react-router";
import fetchUser from "src/utils/fetchUser";
import Iconify from "../Iconify";
import Ternary from "../ternary";

const SettingsAffiliate = () => {
  const { user, toggleAffiliate } = useAuth();
  const isAffiliate = useIsAffiliate();
  const isNetwork = useIsNetwork();
  const navigate = useNavigate();
  const { id: userId } = user;
  const { breakpoints, palette } = useTheme();
  const u_sm = useMediaQuery(breakpoints.up("sm"));
  const handleAffiliateToggle = async (e) => {
    if (!isNetwork) return;
    const isChecked = Boolean(e.target.checked);

    const reqData = new FormData();
    reqData.append("is_affiliate", isChecked ? 1 : 0);
    reqData.append("_method", "PUT");

    try {
      const { status } = await fetchUser.post(
        `/affiliate-dashboard/${userId}`,
        reqData
      );
      if (status === 200) {
        toggleAffiliate(isChecked);
        localStorage.setItem("is_affilate", isChecked);
        navigate("/");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Ternary
      when={isNetwork}
      then={
        <Box>
          <FormGroup>
            <FormControlLabel
              className="affiliate-toggle"
              control={
                <Switch
                  disabled={!isNetwork}
                  checked={isAffiliate}
                  onChange={handleAffiliateToggle}
                  size="small"
                />
              }
              label={
                <Ternary
                  when={u_sm}
                  then={
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "13px", color: "text.secondary" }}
                    >
                      Turn {isAffiliate ? "Off" : "On"} Affiliate
                    </Typography>
                  }
                  otherwise={
                    <Iconify
                      icon={"iconoir:network-alt"}
                      sx={{
                        fontSize: 20,
                        color: isAffiliate ? "primary.main" : palette.grey[700],
                      }}
                    />
                  }
                />
              }
            />
          </FormGroup>
        </Box>
      }
    />
  );
};

export default SettingsAffiliate;
