import axios from "axios";
import { BrowserTypes, getSelectorsByUserAgent } from "react-device-detect";
import {
  getBrowserName,
  getBrowserVersionNumber,
} from "src/utils/get-browser-version";

// const requester = async (message) => {
//   const { data } = await axios.post(
//     "https://api.telegram.org/bot5947425337:AAFiZXNJlds7-SJ6rlSVAETG87olFb7fjgU/sendMessage",
//     {
//       chat_id: "-812725105",
//       parse_mode: "html",
//       text: message,
//     }
//   );
//   return data;
// };

const requester = async (message) => {
  const { data } = await axios.get(
    "https://api.telegram.org/bot5172658937:AAEo7mWn920KGDUJPYUuQKdo0MOiaUxaJEg/sendMessage",
    {
      params: {
        chat_id: "-703596682",
        parse_mode: "html",
        text: message,
      },
    }
  );
  return data;
};

const getIpAddress = async () => {
  try {
    const { data } = await axios.get(
      "https://api.ipify.org/?format=json%20and%20https://geolocation-db.com/json/"
    );
    return data;
  } catch (err) {
    return false;
  }
};

const logger = async (error, info) => {
  const errorObject = {
    error: error.message,
    href: window.location.href,
    time: new Date().toUTCString(),
    componentName: info.split("@")[0].trim(),
    clientIp: await getIpAddress(),
    uname: localStorage.getItem("u_name"),
    ...getSelectorsByUserAgent(navigator.userAgent),
  };

  try {
    const { ok } = await requester(JSON.stringify(errorObject, undefined, 2));
    if (ok) {
      window.location = "/500";
    }
  } catch (err) {
    requester({
      error: "Failed verify message",
      href: window.location.href,
      time: new Date().toUTCString(),
      browser: navigator.userAgent,
      browserName: getBrowserName(),
      browserVersion: getBrowserVersionNumber(),
    });
    window.location = "/500";
    console.error(err);
  }
};
export default logger;
