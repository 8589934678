import { Navigate } from "react-router-dom";

import { lazy } from "react";
import Loadable from "../Loadable";
const Maintenance = Loadable(lazy(() => import("./page/maintenance")));

const MAINTENANCE_ROUTE = [
  { path: "/", element: <Maintenance /> },
  { path: "*", element: <Navigate to="/" replace /> },
];

export default MAINTENANCE_ROUTE;
