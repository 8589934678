import { useFormContext } from "react-hook-form";
import Ternary from "src/components/ternary";
import { AUTH_INFO, DEV_MODE } from "src/config";
import AuthBanner from "./authBanner";

const AuthHelper = () => {
  const { reset } = useFormContext();
  const { admin, user } = AUTH_INFO;

  const selectCredentials = (info) => () => reset({ remember: true, ...info });

  return (
    <Ternary
      when={DEV_MODE}
      then={
        <>
          <AuthBanner info={admin} onClick={selectCredentials(admin)} />
          <AuthBanner info={user} onClick={selectCredentials(user)} />
        </>
      }
    />
  );
};

export default AuthHelper;
