import { createContext, useContext, useReducer } from "react";

export const subscriptionDataContext = createContext(null);
export const subscriptionDispatchContext = createContext(() => {});

const SET = "SET";

export const setGuidance = (payload) => ({ type: SET, payload });

const reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SET: {
      if (payload) {
        const { description, intro_block } = payload;
        return { description, block: intro_block };
      }
      return null;
    }
    default: {
      return state;
    }
  }
};

const UserGuidanceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, null);

  return (
    <subscriptionDataContext.Provider value={state}>
      <subscriptionDispatchContext.Provider value={dispatch}>
        {children}
      </subscriptionDispatchContext.Provider>
    </subscriptionDataContext.Provider>
  );
};

export const useUserGuidanceData = () => useContext(subscriptionDataContext);
export const useUserGuidanceDispatch = () =>
  useContext(subscriptionDispatchContext);

export default UserGuidanceProvider;
