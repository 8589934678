import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

const Faq = Loadable(
    lazy(() => import("src/pages/userSide/helpCenter/faq/index"))
);

const ContactSupport = Loadable(
    lazy(() => import("src/pages/userSide/helpCenter/ticket/contact/index"))
);

const MySupportTicket = Loadable(
    lazy(() => import("src/pages/userSide/helpCenter/ticket/mysupport/index"))
);

const OtherSupport = Loadable(
    lazy(() => import("src/pages/userSide/helpCenter/ticket/others/index"))
);

const Tickets = Loadable(
    lazy(() => import("src/pages/userSide/helpCenter/materialTickets/index"))
);

const TicketView = Loadable(
    lazy(() =>
        import("src/pages/userSide/helpCenter/ticket/mysupport/viewTickets")
    )
);

const Video = Loadable(
    lazy(() => import("src/pages/userSide/helpCenter/video/Index"))
);

const DownloadDoc = Loadable(
    lazy(() => import("src/pages/userSide/helpCenter/DowloadDoc/Index"))
);

// const Emails = Loadable(
//   lazy(() => import("src/routes/dashboard/communication/main"))
// );

const Mail = Loadable(
    lazy(() => import("src/pages/communications/Mail/user/index"))
);

const MailList = Loadable(
    lazy(() => import("src/pages/communications/Mail/user/mailList"))
);

const MailDetails = Loadable(
    lazy(() =>
        import("src/pages/communications/Mail/subPages/MailDetails/index")
    )
);

const Compose = Loadable(
    lazy(() => import("src/pages/communications/Mail/user/compose/index"))
);

const helpCenter = {
    path: "help-center",
    children: [
        { index: true, element: <Navigate to='faqs' /> },
        {
            path: "faqs",
            children: [
                { index: true, element: <Navigate to='faq' /> },
                {
                    path: ":label",
                    element: <Faq />,
                },
            ],
        },
        {
            path: "videos",
            children: [
                {
                    index: true,
                    element: <Video />,
                },
            ],
        },

        {
            path: "documents",
            children: [
                {
                    index: true,
                    element: <DownloadDoc />,
                },
            ],
        },
        {
            path: "mails",
            element: <Mail />,
            children: [
                { index: true, element: <Navigate to='inbox' /> },
                { path: "compose", element: <Compose /> },
                { path: "replay/:id", element: <Compose /> },
                {
                    path: ":emailLabel",
                    children: [
                        {
                            index: true,
                            element: <MailList />,
                        },
                        {
                            path: ":mailId",
                            element: <MailDetails />,
                        },
                    ],
                },
            ],
        },

        {
            path: "create-ticket",
            children: [
                {
                    index: true,
                    element: <Navigate to='open' />,
                },

                {
                    path: ":label",
                    children: [
                        { index: true, element: <Tickets /> },
                        { path: ":id", element: <TicketView /> },
                    ],
                },

                {
                    path: "contact-support",
                    element: <ContactSupport />,
                },
                {
                    path: "my-support-ticket",
                    element: <MySupportTicket />,
                },
                {
                    path: "other-support",
                    element: <OtherSupport />,
                },
            ],
        },
    ],
};

export default helpCenter;
