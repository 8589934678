import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

const LocalStorageClear = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClearLocalStorage = () => {
    localStorage.clear();
    handleClose();
    enqueueSnackbar("Your local storage data is clear");
    window.location.reload(true);
    // You can also remove specific items from localStorage using:
    // localStorage.removeItem('itemName');
  };

  return (
    <>
      <Typography
        variant="caption"
        align="right"
        sx={{ mt: 2, color: "#637381" }}
      >
        Clear browsing data ?&nbsp;
        <a
          href="#"
          onClick={handleClickOpen}
          style={{
            color: "#7558A7",
            fontWeight: 600,
            textDecoration: "none",
            fontFamily: "Public Sans,sans-serif",
          }}
          name="cache-clear"
        >
          click here
        </a>
      </Typography>

      {/* Dialogue  */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="subtitle1">Clear browsing data</Typography>
        </DialogTitle>

        <DialogContent>
          <Typography
            variant="subtitle2"
            sx={{ color: "#637381", mt: 2, mb: 3 }}
          >
            To clear browsing data from this device only, while keeping it in
            your Account Clear history, cookies, cache, and more...
          </Typography>
          <Box textAlign="end">
            <Button
              onClick={handleClose}
              color="error"
              mr={2}
              size="small"
              name="cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={handleClearLocalStorage}
              autoFocus
              variant="contained"
              color="warning"
              size="small"
              name="clear"
            >
              Clear
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LocalStorageClear;
