import { PATH_DASHBOARD } from "src/routes/paths";

const menu = [
  {
    title: "products",
    to: PATH_DASHBOARD.store.products,
    icon: "material-symbols:shopping-cart-outline",
  },
  {
    title: "materials",
    to: PATH_DASHBOARD.store.material.root,
    icon: "material-symbols:folder-outline",
  },
  {
    title: "help center",
    to: PATH_DASHBOARD.communication.help_center,
    icon: "bx:support",
  },
  {
    title: "network settings",
    to: PATH_DASHBOARD.settings.network,
    icon: "material-symbols:shopping-cart-outline",
  },
];

export default menu;
