import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

const FidnSponser = Loadable(
  lazy(() =>
    import("src/pages/userSide/dashboard/new/componets/FindSponser/Index")
  )
);

const findSponser = {
  path: "sponsor",
  children: [
    { index: true, element: <Navigate to="find" /> },
    { path: "find", element: <FidnSponser /> },
  ],
};

export default findSponser;
