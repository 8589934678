import { useSnackbar } from "notistack";
import { useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";

const useEditorCache = (name, cacheData = false) => {
  const { watch, setValue } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const KEY = `editor-${name}`;
  const firstLoad = useRef(true);
  const cache = sessionStorage.getItem(KEY);
  const value = watch(name);

  useMemo(() => {
    if (cacheData)
      if (value) {
        try {
          sessionStorage.setItem(KEY, value);
        } catch (err) {
          enqueueSnackbar("Size exceeded maximum capacity", {
            variant: "error",
          });
          setValue(name, cache);
        }
      }
  }, [value]);

  useMemo(() => {
    if (firstLoad && cacheData) {
      setValue(name, cache);
      firstLoad.current = false;
    }
  }, []);
};

export default useEditorCache;
