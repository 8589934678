import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

const Subscriptions = Loadable(
  lazy(() => import("src/pages/userSide/businessBuilder/subscriptions/index"))
);

const Materials = Loadable(
  lazy(() => import("src/pages/userSide/businessBuilder/materials/index"))
);

const OrderHistory = Loadable(
  lazy(() => import("src/pages/userSide/businessBuilder/orderHistory/index"))
);

const Documents = Loadable(
  lazy(() =>
    import(
      "src/pages/userSide/businessBuilder/materials/subPages/documents/index"
    )
  )
);

const Videos = Loadable(
  lazy(() =>
    import("src/pages/userSide/businessBuilder/materials/subPages/videos/index")
  )
);
const BusinessBuilderInvoice = Loadable(
  lazy(() => import("src/pages/userSide/businessBuilder/Invoice/Index"))
);

const Affiliate = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/affiliate/index"))
);

const Sponsor = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/sponsor/index"))
);

const BinaryTree = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/binary/index"))
);

const BinaryLeg = Loadable(
  lazy(() => import("src/pages/userSide/genealogy/binaryLeg/index"))
);

const AddCredit = Loadable(
  lazy(() => import("src/pages/userSide/financial/deposit/addCredit"))
);

const RequestPayout = Loadable(
  lazy(() => import("src/pages/userSide/financial/requestPayout/index"))
);

const EWallet = Loadable(
  lazy(() => import("src/pages/userSide/financial/ewallet/index"))
);

const DepositWallet = Loadable(
  lazy(() => import("src/pages/userSide/financial/deposit/index"))
);

const FundTransfer = Loadable(
  lazy(() => import("src/pages/userSide/financial/fundTransfer/index"))
);

const Events = Loadable(
  lazy(() =>
    import("src/pages/userSide/businessBuilder/materials/subPages/events/index")
  )
);

const ReferralDetails = Loadable(
  lazy(() =>
    import("src/pages/userSide/genealogy/affiliate/referrals/ReferralDetails")
  )
);

const businessBuilder = {
  path: "business-builder",
  children: [
    { index: true, element: <Navigate to="subscriptions" /> },
    {
      path: "genealogy",
      children: [
        {
          path: "affiliate",
          children: [
            {
              index: true,
              element: <Affiliate />,
            },
            {
              path: ":id",
              element: <ReferralDetails />,
            },
          ],
        },
        { path: "binary-leg", element: <BinaryLeg /> },

        {
          path: "binary",
          children: [
            { index: true, element: <BinaryTree /> },
            { path: ":uname", element: <BinaryTree /> },
          ],
        },
        {
          path: "sponsor",
          children: [
            { index: true, element: <Sponsor /> },
            { path: ":uname", element: <Sponsor /> },
          ],
        },
      ],
    },

    {
      path: "financial",
      children: [
        { path: "e-wallet", element: <EWallet /> },
        {
          path: "deposit-wallet",
          children: [
            { index: true, element: <DepositWallet /> },
            { path: "add-credit", element: <AddCredit /> },
          ],
        },
        { path: "funds-transfer", element: <FundTransfer /> },
        { path: "payout", element: <RequestPayout /> },
      ],
    },

    {
      path: "subscriptions",
      children: [
        {
          index: true,
          element: <Subscriptions />,
        },
        {
          path: "invoice/:id",
          element: <BusinessBuilderInvoice />,
        },
      ],
    },
    {
      path: "history",
      element: <OrderHistory />,
    },
    {
      path: "materials",
      element: <Materials />,
      children: [
        { index: true, element: <Navigate to="documents" /> },

        {
          path: "documents",
          element: <Documents />,
        },

        {
          path: "videos",
          element: <Videos />,
        },
        {
          path: "events",
          element: <Events />,
        },
      ],
    },
  ],
};

export default businessBuilder;
