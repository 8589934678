import { LoadingButton } from "@mui/lab";
import { Button, Link, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Iconify from "src/components/Iconify";
import Password from "src/components/Password";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import { PATH_AUTH } from "src/routes/paths";
import AuthHelper from "./components/authHelper";
import ErrorBanner from "./components/errorBanner";
import TwoFactor from "./components/twoFactor";
import useGoogleOAuth from "./hooks/useGoogleOAuth";
import useLogin from "./hooks/useLogin";

export default function LoginForm() {
  const gLogin = useGoogleOAuth();

  const { methods, onSubmit, openScanner } = useLogin();
  const {
    formState: { isSubmitting },
  } = methods;
  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={3}>
          <AuthHelper />
          <ErrorBanner />
          <RHFTextField name="email" label="Email address / Username" />
          <Password name="password" label="Password" />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Link
            component={RouterLink}
            variant="subtitle2"
            to={PATH_AUTH.resetPassword}
          >
            Forgot password?
          </Link>
        </Stack>

        <Stack spacing={2}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
          <Button
            variant="outlined"
            fullWidth
            size="large"
            onClick={gLogin}
            endIcon={<Iconify icon="logos:google-icon" />}
          >
            Sign in with Google
          </Button>
        </Stack>
      </FormProvider>

      <TwoFactor methods={methods} open={openScanner} />
    </>
  );
}
