import { lazy } from "react";
import GuestGuard from "src/guards/GuestGuard";
import Loadable from "./Loadable";

const Login = Loadable(lazy(() => import("src/pages/auth/Login")));
const Register = Loadable(lazy(() => import("src/pages/auth/register")));
const ResetPassword = Loadable(
  lazy(() => import("src/pages/auth/reset-password/index"))
);
const VerifyCode = Loadable(lazy(() => import("src/pages/auth/VerifyCode")));
const NewPassword = Loadable(
  lazy(() => import("src/pages/auth/NewPassword/index"))
);
const ChangedPassword = Loadable(
  lazy(() => import("src/pages/auth/ChangedPassword"))
);

const WordPressLogin = Loadable(
  lazy(() => import("src/pages/auth/wpLogin/index"))
);

const auth = [
  { path: "auth/wp/:auth_token", element: <WordPressLogin /> },
  {
    path: "auth",
    element: <GuestGuard />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        children: [
          { index: true, element: <Register /> },
          {
            path: ":uname",
            element: <Register />,
          },
        ],
      },
      { path: "login-unprotected", element: <Login /> },
      { path: "register-unprotected", element: <Register /> },
      { path: "reset-password", element: <ResetPassword /> },
      { path: "verify", element: <VerifyCode /> },
      { path: "new-password/", element: <NewPassword /> },
      { path: "password-reset/success", element: <ChangedPassword /> },
    ],
  },
];

export default auth;
