import axios from "axios";
import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import axiosInstance from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";

const initialState = {
	isAuthenticated: false,
	isInitialized: false,
	isAdmin: false,
	isSubAdmin: false,
	user: null,
};

const handlers = {
	TOGGLE_AFFILIATE: (state, { payload }) => {
		return {
			...state,
			user: {
				...state?.user,
				user_profile: {
					...state?.user.user_profile,
					is_affiliate: payload,
				},
			},
		};
	},
	RESET_CART: (state) => {
		return {
			...state,
			user: { ...state.user, cartCount: 0 },
		};
	},

	INCREMENT_CART: (state, { payload }) => {
		return {
			...state,
			user: { ...state.user, cartCount: payload },
		};
	},

	DECREMENT_CART: (state, { payload }) => {
		return {
			...state,
			user: { ...state.user, cartCount: state.user.cartCount - payload },
		};
	},

	SET_PROFILE_IMAGE: (state, { payload }) => {
		return {
			...state,
			user: {
				...state.user,
				user_profile: {
					...state.user.user_profile,
					profile_image: payload,
				},
			},
		};
	},
	SET_IMAGE: (state, { payload }) => {
		return {
			...state,
			user: {
				...state.user,
				user_profile: {
					...state.user.user_profile,
					cover_image: payload,
				},
			},
		};
	},
	INITIALIZE: (state, action) => {
		const { isAuthenticated, user } = action.payload;
		return {
			...state,
			isAuthenticated,
			isInitialized: true,
			isAdmin: JSON.parse(localStorage.getItem("isAdmin")),
			isSubAdmin: JSON.parse(localStorage.getItem("isSubAdmin")),
			user,
		};
	},

	LOGIN: (state, action) => {
		return {
			...state,
			isAuthenticated: true,
		};
	},

	GET_USER: (state, action) => {
		const {
			scope_email,
			scope_facebook,
			scope_instagram,
			scope_medium,
			scope_phone,
			scope_telegram,
			scope_twitter,
			scope_whatsapp,
			referral_count,
			...rest
		} = action.payload.user;
		return {
			...state,
			isAuthenticated: true,
			isAdmin: action.payload.isAdmin,
			isSubAdmin: action.payload.isSubAdmin,
			user: {
				...rest,
				social: {
					scope_email,
					scope_facebook,
					scope_instagram,
					scope_medium,
					scope_phone,
					scope_telegram,
					scope_twitter,
					scope_whatsapp,
				},
			},
		};
	},

	LOGOUT: (state) => ({
		...state,
		isAuthenticated: false,
		isAdmin: false,
		user: null,
	}),
	REGISTER: (state, action) => {
		const { user } = action.payload;

		return {
			...state,
			isAuthenticated: true,
			user,
		};
	},

	UPDATE_USER_NAME: (state, action) => {
		return {
			...state,
			user: { ...state.user, username: action.payload },
		};
	},
};

const reducer = (state, action) =>
	handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
	...initialState,
	method: "jwt",
	toggleAffiliate: () => Promise.resolve(),
	login: () => Promise.resolve(),
	logout: () => Promise.resolve(),
	register: () => Promise.resolve(),
	resetPassword: () => Promise.resolve(),
	getUser: () => Promise.resolve(),
	setImage: () => Promise.resolve(),
	setProfileImage: () => Promise.resolve(),
	incrementCart: () => Promise.resolve(),
	decrementCart: () => Promise.resolve(),
	resetCart: () => Promise.resolve(),
	userGuidance: () => Promise.resolve(),
	updateUserName: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
	children: PropTypes.node,
};

function AuthProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		const initialize = async () => {
			try {
				const accessToken = window.localStorage.getItem("accessToken");

				if (accessToken && isValidToken(accessToken)) {
					setSession(accessToken);
					const user = {};
					dispatch({
						type: "INITIALIZE",
						payload: {
							isAuthenticated: true,
							user,
						},
					});
				} else {
					dispatch({
						type: "INITIALIZE",
						payload: {
							isAuthenticated: false,
							user: null,
						},
					});
				}
			} catch (err) {
				console.error(err);
				dispatch({
					type: "INITIALIZE",
					payload: {
						isAuthenticated: false,
						user: null,
					},
				});
			}
		};

		initialize();
	}, []);

	const login = async (email, password, remember) => {
		const formData = new FormData();
		formData.append("email", email);
		formData.append("password", password);
		formData.append("remember", remember);

		try {
			const URL = `${process.env.REACT_APP_HOST_NAME}api/login`;
			const { status, data } = await axios.post(URL, formData);
			if (status === 200) {
				const { access_token, user, message, secret, menu_lists } =
					data;
				if (!message) {
					localStorage.setItem("menu", JSON.stringify(menu_lists));
					localStorage.setItem(
						"isAdmin",
						Boolean(user.is_super_admin)
					);
					localStorage.setItem(
						"isSubAdmin",
						Boolean(user.is_sub_admin)
					);
					localStorage.removeItem("isImpersonate");
					localStorage.setItem("u_name", user.username);
					setSession(access_token);

					if (Boolean(user.is_sub_admin)) {
						const [menu] = menu_lists;
						const { path, children } = menu?.items[0] || {};

						if (path.includes("dashboard")) {
							window.location = children[0]?.path;
							return;
						}
						window.location = path;
						return;
					}

					getUser();
					return { status: true, data: user.username };
				}
				if (!data.status && data.secret === null) {
					return { data: secret, status: false, twoFactorAuth: true };
				}
				if (message) {
					return { status: false, message };
				} else {
					return { status: false, message };
				}
			}
		} catch (err) {
			return { status: false, message: err.response.data.message };
		}

		return "";
	};

	const register = async (
		email,
		password,
		referral,
		pending_id,
		agree,
		{ isGift },
		first_name
	) => {
		const URI = isGift ? "api/register-friend" : "api/register";

		const formData = new FormData();
		formData.append("email", email);
		formData.append("first_name", first_name);
		formData.append("password", password);
		formData.append("referral", referral);
		formData.append("agree", agree ? 1 : null);
		if (isGift) {
			formData.append("pending_id", pending_id);
		}
		try {
			const response = await axiosInstance.post(URI, formData);

			if (response.status === 200) {
				const { access_token, user, menu_lists } = response.data;
				localStorage.setItem("menu", JSON.stringify(menu_lists));
				window.localStorage.setItem("accessToken", access_token);
				dispatch({
					type: "REGISTER",
					payload: {
						user,
					},
				});
				return {
					status: true,
					message: "registered success Welcome !!!",
				};
			}
		} catch (err) {
			return { status: false, message: err.message, error: err };
		}
	};

	const setImage = async (image) => {
		dispatch({ type: "SET_IMAGE", payload: image });
	};

	const logout = async () => {
		try {
			const { status } = await axiosInstance.post("api/logout");
			if (status === 200) {
				localStorage.removeItem("menu");
				localStorage.removeItem("isAdmin");
				localStorage.removeItem("isSubAdmin");
				localStorage.removeItem("isImpersonate");
				localStorage.removeItem("u_name");
				localStorage.removeItem("source_id");

				setSession(null);
				dispatch({ type: "LOGOUT" });
				window.location = "/auth/login";
			}
		} catch (err) {
			console.log(err);
		}
	};

	const resetPassword = async (inputData) => {
		const reqData = new FormData();
		reqData.append("email", inputData.email);
		reqData.append("password", inputData.password);
		reqData.append("password_confirmation", inputData.repassword);
		reqData.append("token", inputData.token);
		try {
			const { status, data } = await axiosInstance.post(
				`/reset-password`,
				reqData
			);

			if (status === 200) {
				return data;
			}
		} catch (error) {
			return error;
		}
	};

	const getUser = async () => {
		try {
			const { status, data } = await axiosInstance.get("api/profile");
			if (status === 200) {
				const {
					user,
					cart_count,
					referral_count,
					balance_amount,
					total_commission,
					total_payout,
					sponsor_info,
					is_pending,
					is_upgrade_now,
					pending_amount,
					next_sunday,
					bb_active,
				} = data.data;
				const sponsorInfo = {
					name: sponsor_info?.user_profile?.first_name,
					username: sponsor_info?.username,
					country: sponsor_info?.user_profile?.country,
					joinedAt:
						sponsor_info?.created_at &&
						new Date(sponsor_info?.created_at).toLocaleDateString(
							"en-GB"
						),
				};

				if (user)
					dispatch({
						type: "GET_USER",
						payload: {
							user: {
								...user,
								cartCount: cart_count,
								referralCount: referral_count,
								balanceAmount: parseInt(balance_amount),
								totalCommission: total_commission,
								totalPayout: total_payout,
								sponsorInfo,
								is_pending,
								is_upgrade_now,
								pending_amount,
								next_sunday,
								bbActive: bb_active,
							},
							isSubAdmin: JSON.parse(
								localStorage.getItem("isSubAdmin")
							),
							isAdmin: JSON.parse(
								localStorage.getItem("isAdmin")
							),
						},
					});
			}
		} catch (err) {
			console.error(err);
		}
	};

	const setProfileImage = (newUri) => {
		dispatch({ type: "SET_PROFILE_IMAGE", payload: newUri });
	};

	const incrementCart = (cartCount) => {
		dispatch({ type: "INCREMENT_CART", payload: cartCount });
	};

	const decrementCart = () => {
		dispatch({ type: "DECREMENT_CART", payload: 1 });
	};

	const resetCart = () => {
		dispatch({ type: "RESET_CART" });
	};

	const toggleAffiliate = (status) => {
		dispatch({ type: "TOGGLE_AFFILIATE", payload: status });
	};

	const updateUserName = (uname) => {
		dispatch({ type: "UPDATE_USER_NAME", payload: uname });
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: "jwt",
				toggleAffiliate,
				login,
				logout,
				register,
				resetPassword,
				getUser,
				setImage,
				setProfileImage,
				incrementCart,
				decrementCart,
				resetCart,
				updateUserName,
			}}>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
