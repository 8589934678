import { Button } from "@mui/material";
import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
import { PATH_AUTH } from "src/routes/paths";

const ActionButton = ({ type }) => {
  const isRegister = type === "register";
  const label = capitalCase(isRegister ? "sign-up" : "login");
  const link = isRegister ? PATH_AUTH.register : PATH_AUTH.login;
  return (
    <Button
      variant="contained"
      sx={{ width: "15%" }}
      component={RouterLink}
      to={link}
    >
      {label}
    </Button>
  );
};

export default ActionButton;
