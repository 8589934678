import { Stack } from "@mui/material";
import Image from "src/components/Image";

const Message = () => {
  return (
    <Stack
      spacing={3}
      alignItems="center"
      sx={{
        marginBottom: 3,
      }}
    >
      <Image
        src="/bitcoinTaf.png"
        sx={{
          width: "70%",
        }}
      />
    </Stack>
  );
};

export default Message;
