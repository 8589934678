import { useFormContext } from "react-hook-form";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";

const TwoFactorVerificationDialog = ({ qrCode = null }) => {
  const {
    register,
    formState: { isSubmitting, errors },
    getValues,
  } = useFormContext();
  const key = getValues("key");
  return (
    <>
      <DialogTitle
        sx={{
          mb: 2,
        }}
      >
        Two-Factor Authentication (2FA)
      </DialogTitle>
      <DialogContent
        sx={{
          paddingBottom: 0,
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="caption" gutterBottom>
            The first step is to download the Google Authenticator app for your
            Andriod or iOS device. If you need help getting started, please see
            Google Support Page If you do not have access to the Andriod Market
            or App Store, there are other options for getting Google
            Authenticator:, Andriod Download, Google Chrome Plugin, or iTunes
            App Store
          </Typography>
        </Box>

        {qrCode}
        <Typography
          variant="subtitle2"
          color="primary"
          sx={{ mb: 2, textAlign: "center" }}
        >
          KEY: {key}
        </Typography>

        <TextField
          helperText={errors?.code?.message}
          error={Boolean(errors?.code)}
          autoFocus
          {...register("code")}
          size="small"
          fullWidth
          label="Enter OTP"
          type="number"
        />

        {/* <DialogActions> */}
        <LoadingButton
          sx={{
            mt: 1,
            mb: 2,
          }}
          type="submit"
          loading={isSubmitting}
          size="small"
          fullWidth
          variant="contained"
        >
          verify
        </LoadingButton>

        <br />
        <Typography
          variant="caption"
          sx={{
            fontStyle: "italic",
          }}
        >
          The token will not be shown again after 2FA is enabled. If you have
          multiple devices, add your account token to all of them before
          clicking enable. (Note:Your Account Token will change each time you
          reload your browser.)
        </Typography>
      </DialogContent>
      <DialogActions />
    </>
  );
};

export default TwoFactorVerificationDialog;
