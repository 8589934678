import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import useAuth from "src/hooks/useAuth";
import useQueryParams from "src/hooks/useQueryParams";
import { PATH_AUTH } from "src/routes/paths";
import { object, string } from "yup";

const defaultValues = {
  email: "",
  password: "",
  remember: true,
};

const schema = object().shape({
  email: string().required("Email or Username is required"),
  password: string().required("Password is required"),
});

const useLogin = () => {
  const { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [openScanner, setOpenScanner] = useState(false);
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { state } = useLocation();
  const { addParam } = useQueryParams();
  const { reset, setError, setValue, handleSubmit } = methods;

  useEffect(() => {
    if (state) {
      setError("afterSubmit", { message: state });
      navigate(PATH_AUTH.login, { state: "", replace: true });
    }
  }, [state]);

  const onSubmit = async (inputData) => {
    const { status, data, message, twoFactorAuth } = await login(
      inputData.email,
      inputData.password,
      inputData.remember
    );

    if (status) {
      enqueueSnackbar(`Welcome ${data}`, {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      // reset();
      // window.location.reload();
      // navigate("/", { replace: true });
    } else if (twoFactorAuth) {
      addParam("otp", "open");
      setValue("key", data);
      setValue("verify", 1);
      setOpenScanner(true);
    } else {
      setError("afterSubmit", {
        message,
      });
    }
  };

  return { onSubmit: handleSubmit(onSubmit), methods, openScanner };
};

export default useLogin;
