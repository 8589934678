import { Typography } from "@mui/material";

const BrandName = () => (
  <>
    B
    <Typography variant="span" sx={{ fontSize: "1rem" }}>
      ITCOIN
    </Typography>
    TAF
  </>
);

export default BrandName;
